<template>
  <v-row>
    <v-spacer></v-spacer>
    <v-col></v-col>
    <v-col>
      <v-row
        align="center"
        no-gutters
        class="pa-0 ml-5 hidden-md-and-down"
        :class="{
          'text-h5 ': $vuetify.breakpoint.mdAndDown,
          'text-h6': $vuetify.breakpoint.lgAndUp,
        }"
      >
        <v-col cols="12" class="text-left">{{ supportText }}</v-col>
        <v-col cols="12" class="font-weight-black text-left">{{ email }}</v-col>
        <v-col cols="12" class="font-weight-black text-left">{{ phone }}</v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
export default {
  name: "DefaultSupport",
  props: {
    phone: {
      type: String,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
    supportText: {
      type: String,
      required: true,
    },
  },
  data() {
    return {};
  },
};
</script>
